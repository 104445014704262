
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="bottomType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">任务列表</div>
          <div v-if="bottomType!==1" :class="bottomType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">任务办理</div>
        </div>

        <component :is="currentComponent + 'Component'"  :defaultshowAllTask.sync="showAllTask" :id.sync="id"  @on-reloadRight="reloadRight"  @on-showDetail="showTaskDetail" @on-changeShowAllTask="changeShowAllTask" />
    </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['./bottom/TaskList'], resolve)
    },
    taskComponent: (resolve) => {
      require(['./bottom/TaskDetail'], resolve)
    }
  },
  data () {
    return {
      showAllTask: false,
      id: 0,
      taskType: 1, // 任务类型，如开票等
      bottomType: 1, // Bottom类型，用于区别加载不同组件
      currentComponent: 'list'
    }
  },
  created () {
    // this.setShowRightBlock(true)
    // this.setRightComponent('rightList')
  },
  methods: {
    handleChangeTag (val) {
      this.bottomType = val
      if (val === 1) {
        this.id = 0
        this.currentComponent = 'list'
      } else if (val === 2) {
        this.currentComponent = 'task'
      }
      this.$emit('on-changeTask', this.id, this.bottomType)
    },
    // id 任务Id，taskType:任务类型，如：开票
    showTaskDetail (id, taskType) {
      this.id = id
      this.taskType = taskType
      this.handleChangeTag(2)
    },
    reloadRight (id) {
      this.$emit('on-reloadRight', id)
    },
    changeShowAllTask (showAllTask) {
      this.showAllTask = showAllTask
      this.$emit('on-changeShowAllTask', showAllTask)
    }
  }
}
</script>
